import { useGuestSiteTelemetry } from '@apps/guest/GuestSite.telemetry';
import { EventPageFragment, EventPageType } from '@graphql/generated';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from '@shared/core';
import { useEventUserRole } from '@shared/components/AuthProvider';
import { GuestSiteQueryParams, useGuestSiteQueryParams } from './useGuestSiteQueryParams';
import { CUSTOM_PAGE_PATH } from '@apps/guest/routes/GuestSite/GuestSite.routes';

export const getSlugFromPathname = (pathname: string) => {
  const isCustomPagePath = pathname.split('/')[2] === CUSTOM_PAGE_PATH;
  const pagePath = isCustomPagePath ? pathname.split('/')[3] : pathname.split('/')[2];
  return { pagePath, isCustomPagePath };
};

export const useGuestSitePageChanged = (pages: Array<EventPageFragment>) => {
  const telemetry = (useGuestSiteTelemetry() as unknown) as Record<string, { enter: (role: string, args: GuestSiteQueryParams) => void }>;
  const [currentPageSlug, setCurrentPageSlug] = useState<string>();
  const { isAdmin } = useEventUserRole();
  const history = useHistory();
  const queryParams = useGuestSiteQueryParams();

  const trackPage = useCallback(
    locationPathName => {
      const { pagePath, isCustomPagePath } = getSlugFromPathname(locationPathName);
      const pageFound = pages.find(p => p.pageSlug === pagePath && (!isCustomPagePath || p.type === EventPageType.custom));

      if (pageFound && telemetry[pageFound.pageSlug] && currentPageSlug !== pageFound.pageSlug) {
        telemetry[pageFound.pageSlug].enter(isAdmin ? 'admin' : 'guest', queryParams);
        setCurrentPageSlug(pageFound.pageSlug);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pages, history, currentPageSlug]
  );

  useEffect(() => {
    if (history.location.pathname) {
      trackPage(history.location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname]);
};
