import React, { useMemo, useRef } from 'react';
import { EventPageFragment } from '@graphql/generated';
import { BoxProps } from '@withjoy/joykit';
import { LayoutAlohaProps } from '../../LayoutAloha';
import { pageWidgets, PreparedPages } from './Body.constants';
import Page from '../Page';

export interface BodyProps extends Readonly<{ page: Maybe<EventPageFragment>; applicatorProps?: Object } & LayoutAlohaProps & BoxProps> {}

export const Body: React.FC<BodyProps> = ({ event, eventHandle, page, applicatorProps = {}, ...restProps }) => {
  const pageRef = useRef<HTMLDivElement>(null);

  const { isTidbitsPage, isStoryPage, marginTop, isAccommodations } = useMemo(() => {
    return {
      isTidbitsPage: page?.type === 'tidbits',
      isStoryPage: page?.type === 'story',
      marginTop: '4rem',
      isRegistryPage: page?.type === 'registry',
      isAccommodations: page?.pageSlug === 'accommodations'
    };
  }, [page?.pageSlug, page?.type]);

  if (!page) {
    return null;
  }

  const { type } = page;
  const PageWidget = pageWidgets[type as PreparedPages];

  const pageProps = (!isTidbitsPage && {
    title: page.pageTitle,
    subtitle: !isStoryPage ? page.subTitle : undefined,
    graphicAccent: undefined,
    isAccommodations: isAccommodations,
    marginTop
  }) || {
    title: page.pageTitle,
    marginTop
  };
  return PageWidget ? (
    <Page {...pageProps} pageSlug={page.pageSlug} ref={pageRef}>
      <PageWidget
        isAccommodations={isAccommodations}
        event={event}
        eventHandle={eventHandle}
        {...applicatorProps}
        pageId={page.id}
        pageSlug={page.pageSlug}
        pageTitle={pageProps.title}
      />
    </Page>
  ) : null;
};
