import { useHistory } from '@shared/core';
import { withWindow } from '@shared/utils/withWindow';
import { Flex } from '@withjoy/joykit';
import React, { useEffect, useState } from 'react';
import { useMount } from '@shared/utils/hooks/useMount';
import PageNotFound from '@apps/pageNotFound/PageNotFound';
import { useHotelBookingGuestSiteTelemetry } from '../../HotelBooking.telemetry';
import { getValidBookingLink } from '../../HotelBooking.utils';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useQueryParams } from '@shared/utils/hooks/useQueryParams';
import { getConfig } from '@config';
import { generateGoogleUrl } from '@shared/utils/googleUrl';
import { JoyLogoLoader } from '@shared/components/JoyLogoLoader';
import { useEventInfo } from '@shared/utils/eventInfo';
import { useCalculatedCheckIn, useCalculatedCheckOut } from '@shared/hooks/useDateUtils';

/**
 * Formats a Date object into a string in the format 'YYYY-MM-DD'.
 */
const formatDate = (dateObj: Date | null): string | null =>
  dateObj && !isNaN(dateObj.getTime()) ? `${dateObj.getFullYear()}-${String(dateObj.getMonth() + 1).padStart(2, '0')}-${String(dateObj.getDate()).padStart(2, '0')}` : null;
export const HotelBookingPartner: React.FC<{ hotelId: string }> = ({ hotelId }) => {
  const history = useHistory();

  const [showNotFound, setShowNotFound] = useState<boolean>(false);
  const { eventInfo } = useEventInfo();

  const telemetry = useHotelBookingGuestSiteTelemetry();

  const zentrumHubEnabled = useFeatureValue('zentrumHubEnabled').value === 'on';
  const { value, loading } = useFeatureValue('zentrumHubPartnerEnabled', {
    skip: !zentrumHubEnabled
  });

  const checkIn = useCalculatedCheckIn(eventInfo?.finalizedDate?.dateString || undefined);
  const checkOut = useCalculatedCheckOut(eventInfo?.finalizedDate?.dateString || undefined);

  const queryParams = useQueryParams();

  const isDev = getConfig().environment === 'dev';

  useMount(() => {
    telemetry.hotelbooking.enter();
  });

  useEffect(() => {
    if (loading || !hotelId || value === undefined) return;

    withWindow(() => {
      /**
       * Redirect to GoogleUrl
       */
      if (value !== 'on') {
        const { lat, lng, gplid, name, address } = queryParams;
        const googleUrl = generateGoogleUrl({
          placeId: gplid as string,
          name: name as string,
          address: address as string,
          lat: lat as string,
          lng: lng as string
        });
        const validHotelLink = getValidBookingLink(googleUrl);
        telemetry.guestPartnerHotelBookingRedirect(googleUrl, validHotelLink?.hostname || '', true);
        window.location.replace(googleUrl);
        return;
      }

      /**
       * Redirect to ZH Url
       */
      const zhUrl = `https://hotels.withjoy.com/hotels/v2/${hotelId}?campaignid=${eventInfo?.eventId || ''}${checkIn ? `&checkin=${formatDate(checkIn)}` : ''}${
        checkOut ? `&checkout=${formatDate(checkOut)}` : ''
      }`;
      const validBookingLink = getValidBookingLink(zhUrl);
      if (validBookingLink) {
        telemetry.guestPartnerHotelBookingRedirect(zhUrl, validBookingLink.hostname, true);
        window.location.replace(zhUrl);
      } else {
        telemetry.guestPartnerHotelBookingRedirect(zhUrl, zhUrl, false);
        setShowNotFound(true);
      }
    });
  }, [checkIn, checkOut, eventInfo?.eventId, history, hotelId, isDev, loading, queryParams, queryParams.gplid, telemetry, value]);

  if (showNotFound) {
    return <PageNotFound />;
  }

  return (
    <Flex justifyContent="center" alignItems="center" height="100vh" width="100vw">
      <JoyLogoLoader loaderKey="guest-hotel-booking-partner" />
    </Flex>
  );
};
