import { useMemo } from 'react';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useAccommodationsEditorLeadStateQuery } from '@graphql/generated';
import { useAuth, useEventUserRole } from '@shared/components/AuthProvider';
import { useIsHotelBlockAccommodationsFlagEnabled } from '@apps/admin/common/hooks/useIsHotelBlockAccommodationsFlagEnabled';

export function useGuestAccommodationsInfo(eventHandle: string) {
  const { currentUser } = useAuth();
  const { isAdmin, fetching } = useEventUserRole();
  const hotelTileGuestCountEnabled = useFeatureValue('accommodationsGuestCountEnabled').value === 'on';
  const isHotelBlockAccommodationsFlagEnabled = useIsHotelBlockAccommodationsFlagEnabled();

  const isSuperAdmin = currentUser && currentUser.profile?.superAdmin; // e.g. @withjoy.com email

  const { data: accommodationsLeadStateData, loading: accommodationsLeadStateLoading } = useAccommodationsEditorLeadStateQuery({ variables: { eventHandle }, batchMode: 'fast' });

  const leadState = accommodationsLeadStateData?.eventByName?.hotelRoomBlocks?.leadStateV2;
  const hasBookingLink = !!accommodationsLeadStateData?.eventByName?.hotelRoomBlocks?.bookingLink;
  const hasRoomBlock =
    accommodationsLeadStateData?.eventByName?.hotelRoomBlocks?.reservedRoomBlocks && accommodationsLeadStateData?.eventByName?.hotelRoomBlocks?.reservedRoomBlocks.length !== 0;

  const enabledAdminGuestSiteBannerDialog = useMemo<boolean>(() => {
    if (accommodationsLeadStateLoading || fetching || (!leadState && hasRoomBlock)) {
      return false;
    }
    return !leadState && (isAdmin || !!isSuperAdmin) && isHotelBlockAccommodationsFlagEnabled;
  }, [accommodationsLeadStateLoading, fetching, leadState, hasRoomBlock, isAdmin, isSuperAdmin, isHotelBlockAccommodationsFlagEnabled]);

  const hideAccomPreviewUntilFlagEnabled = useMemo<boolean>(() => {
    if (fetching) {
      return true;
    }
    if (isSuperAdmin) {
      return false;
    }

    return isAdmin && !isHotelBlockAccommodationsFlagEnabled;
  }, [fetching, isSuperAdmin, isAdmin, isHotelBlockAccommodationsFlagEnabled]);

  return {
    hideAccomPreviewUntilFlagEnabled,
    enabledAdminGuestSiteBannerDialog,
    hotelTileGuestCountEnabled,
    hasBookingLink
  };
}
