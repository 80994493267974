import React from 'react';
import { EventPageType } from '@graphql/generated';

import { CoupleStory } from '../../../../widgets/CoupleStory';
import { FaqList } from '../../../../widgets/FaqList';
import { ScheduleList } from '../../../../widgets/ScheduleList';
import { TravelList } from '../../../../widgets/TravelList';
import { WeddingPartyList } from '../../../../widgets/WeddingPartyList';
import { LayoutBrannanProps } from '../../LayoutBrannan';
import { Box, ColumnV2Props, Flex } from '@withjoy/joykit';
import { EventLogisticsBlock } from '../../../../components/EventLogisticsBlock';
import { RegistryList } from '@apps/guest/packages/layout-engine/widgets/RegistryList';
import { CtaButtons } from '@apps/guest/packages/layout-engine/components/CtaButtons/CtaButtons';
import Apps from '@apps/guest/packages/layout-engine/widgets/Apps';
import { CONTENT_BLOCK_MAX_WIDTH } from '../../LayoutBrannan.theme';
import Custom from '@apps/guest/packages/layout-engine/widgets/Custom';
import { ContentColumns } from '@apps/guest/packages/layout-engine/primitives';

export type PreparedPages =
  | EventPageType.app
  | EventPageType.registry
  | EventPageType.travel
  | EventPageType.schedule
  | EventPageType.faq
  | EventPageType.vip
  | EventPageType.story
  | EventPageType.welcome
  | EventPageType.custom;

export const pageColumnProps: Readonly<Record<PreparedPages, ColumnV2Props>> = {
  [EventPageType.app]: {},
  [EventPageType.faq]: { span: [10, 6] },
  [EventPageType.registry]: { span: [10] },
  [EventPageType.schedule]: { span: [12, 10, 8] },
  [EventPageType.story]: { span: [10, 10, 8] },
  [EventPageType.travel]: {},
  [EventPageType.vip]: { span: [10, 10] },
  [EventPageType.welcome]: {},
  [EventPageType.custom]: {}
};

export const pageWidgets: Readonly<Record<PreparedPages, (props: LayoutBrannanProps) => React.ReactElement>> = {
  [EventPageType.custom]: props => (
    <>
      {props.isAccommodations ? (
        <Flex data-testid={'page-custom'} justifyContent="center" alignItems="center" margin={'0 auto'}>
          <Custom
            pageId={props.pageId || ''}
            eventId={props.event.id}
            pageSlug={props.pageSlug || ''}
            pageTitle={props.pageTitle}
            isBrannanLayout
            subTitle={props.subTitle}
            eventHandle={props.eventHandle}
          />
        </Flex>
      ) : (
        <Box data-testid={'page-custom'} justifyContent="center" alignItems="center" margin={'0 auto'}>
          <ContentColumns>
            <ContentColumns.Column offset={[0, 1]} span={[12, 10]}>
              <Custom
                pageId={props.pageId || ''}
                eventId={props.event.id}
                pageSlug={props.pageSlug || ''}
                pageTitle={props.pageTitle}
                isBrannanLayout
                subTitle={props.subTitle}
                eventHandle={props.eventHandle}
              />
            </ContentColumns.Column>
          </ContentColumns>
        </Box>
      )}
    </>
  ),
  [EventPageType.app]: () => (
    <Flex data-testid={'page-apps'} justifyContent="center" alignItems="center" margin={'0 auto'} maxWidth={CONTENT_BLOCK_MAX_WIDTH}>
      <Apps data-testid={'page-app'} />
    </Flex>
  ),
  [EventPageType.faq]: props => <FaqList data-testid={'page-faq'} eventHandle={props.eventHandle} />,
  [EventPageType.registry]: props => <RegistryList eventId={props.event.id} eventHandle={props.eventHandle} />,
  [EventPageType.schedule]: props => <ScheduleList data-testid={'page-schedule'} eventHandle={props.eventHandle} variant={'inline'} eventId={props.event.id} />,
  [EventPageType.story]: props => <CoupleStory data-testid={'page-story'} eventHandle={props.eventHandle} />,
  [EventPageType.travel]: props => (
    <TravelList data-testid={'page-travel'} eventHandle={props.eventHandle} eventId={props.event.id} useMiniNav={false} margin={'0 auto'} maxWidth={CONTENT_BLOCK_MAX_WIDTH} />
  ),
  [EventPageType.vip]: props => <WeddingPartyList data-testid={'page-vip'} graphicAccent={props.event.eventDesign?.accent} eventId={props.event.id} />,
  [EventPageType.welcome]: props => (
    <Box data-testid={'page-welcome'}>
      <EventLogisticsBlock location={props.event.info.location} date={props.event.info.finalizedDate} hideEventCountdown={props.event.settings.hideEventCountdown}>
        <CtaButtons />
      </EventLogisticsBlock>
    </Box>
  )
};
