import React from 'react';
import { EventPageFragment, EventPageType } from '@graphql/generated';
import { Box, BoxProps, ColumnsV2, Flex, TextV2 } from '@withjoy/joykit';
import { LayoutBrannanProps } from '../../LayoutBrannan';
import { pageColumnProps, pageWidgets, PreparedPages } from './Body.constants';
import { shouldRenderGraphicAccent } from '../../../layout.utils';
import { PrettyLink } from '@apps/guest/packages/layout-engine/components/PrettyLink';
import GraphicAccent from '@apps/guest/packages/layout-engine/components/GraphicAccent';
import { useSlapAdsTravelClosed } from '@apps/guest/packages/layout-engine/components/SlapAdsTravel';
import { useEventUserRole } from '@shared/components/AuthProvider';
import { useGuestSiteState } from '@apps/guest/routes/GuestSite/GuestSite.state.provider';
import { InlineEditor } from '@shared/components';
import { useTranslation } from '@shared/core';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { sendMessageToParentWindow } from '@shared/utils/previewMessageBus';

export interface BodyProps extends Readonly<{ page: Maybe<EventPageFragment>; photo?: React.ReactNode } & LayoutBrannanProps & BoxProps> {}

const Body: React.FC<BodyProps> = ({ page, event, eventHandle, photo, ...restProps }) => {
  const slapAdsTravelClosed = useSlapAdsTravelClosed();
  const { isAdmin } = useEventUserRole();
  const { isNativeAccommodations, isAccommodations } = useGuestSiteState();

  const { t2 } = useTranslation('joykit');
  const inlineEditorTrans = t2('inlineEditor');

  const handleEditTextClick = useEventCallback((textContent: string, label: string, pageId: string) => {
    // send the message to the parent window to open the text editor
    sendMessageToParentWindow({
      action: 'inlineEditingInteraction',
      source: 'joyPreview',
      value: { action: 'editTextArea', inlineEditData: { textContent, label, pageId, validationConstraints: { maxLength: 140 } } }
    });
  });

  if (!page) {
    return null;
  }
  const { type, pageTitle, subTitle, pageSlug, id } = page;
  const graphicAccent = event.eventDesign?.accent;

  const PageWidget = pageWidgets[type as PreparedPages];
  const isWelcomePage = type === EventPageType.welcome;
  const isSchedulePage = type === EventPageType.schedule;
  const isStoryPage = type === EventPageType.story;
  const isTravelPage = type === EventPageType.travel;
  const isCustomPage = type === EventPageType.custom;

  const pageContentMarkup = PageWidget && (
    <ColumnsV2.Column
      id={pageSlug}
      {...(isCustomPage && !isAccommodations ? { span: [10, 10, 8] } : { ...pageColumnProps[type as PreparedPages] })}
      marginTop={isWelcomePage || isSchedulePage ? undefined : isCustomPage ? 0 : [10, null, 0]}
    >
      <PageWidget isAccommodations={isAccommodations} event={event} eventHandle={eventHandle} pageId={id} pageSlug={pageSlug} pageTitle={pageTitle} subTitle={subTitle} />
    </ColumnsV2.Column>
  );

  const graphicAccentMarkup = shouldRenderGraphicAccent(graphicAccent) && (
    <GraphicAccent id="graphic-accent" marginBottom={8} data-testid={'body-accent'} accentId={graphicAccent!} />
  );

  return (
    <ColumnsV2 {...restProps}>
      <ColumnsV2.Column style={isCustomPage ? { display: isNativeAccommodations && !subTitle ? 'none' : 'block' } : {}} span={[12, 10]} offset={[0, 1]}>
        {photo}
        <Flex
          flexDirection={'column'}
          alignItems={'center'}
          marginTop={isAccommodations ? { _: 6, sm2: 9 } : isCustomPage ? { _: '8rem', sm2: '7rem', md: 9 } : 9}
          marginBottom={isStoryPage || (isAdmin && !slapAdsTravelClosed && isTravelPage) ? 0 : isAccommodations ? { _: 6, sm2: 9 } : 9}
          marginX={isCustomPage ? [6, 'auto'] : [6, null, 0]}
          maxWidth={isCustomPage ? '580px' : null}
        >
          {graphicAccentMarkup}
          {isWelcomePage && pageContentMarkup}
          {!isWelcomePage && !isStoryPage && !isNativeAccommodations && (
            <TextV2
              data-testid={'body-pagetitle'}
              typographyVariant={'display4'}
              fontSize={isAccommodations ? { _: 'clamp(2rem,5cqi,3rem)', sm2: 'clamp(1.8rem,5cqi,3rem)' } : '3rem'}
              textAlign="center"
              wordBreak="break-word"
            >
              {pageTitle}
            </TextV2>
          )}
          {!isStoryPage &&
            subTitle &&
            (isWelcomePage ? (
              <Box marginTop={9}>
                <InlineEditor
                  elementLabel={inlineEditorTrans.greetings}
                  actionData={{
                    edit: () => handleEditTextClick(subTitle, inlineEditorTrans.greetings, id)
                  }}
                  wrapperCSS={{
                    zIndex: 1200
                  }}
                  componentName="greetings"
                  pageName={type}
                  pageSlug={pageSlug}
                >
                  <TextV2 data-testid="body-subtitle" tagName="div" typographyVariant={'body4'} maxWidth={500} textAlign="center">
                    <PrettyLink source={page.pageSlug}>{subTitle}</PrettyLink>
                  </TextV2>
                </InlineEditor>
              </Box>
            ) : (
              <TextV2 data-testid="body-subtitle" tagName="div" typographyVariant={'body4'} marginTop={4} maxWidth={500} textAlign="center">
                <PrettyLink source={page.pageSlug}>{subTitle}</PrettyLink>
              </TextV2>
            ))}
        </Flex>
      </ColumnsV2.Column>

      {!isWelcomePage && (
        <Box width={'100%'} marginX={[isAccommodations ? 0 : 7, null, 0]} display={'flex'} justifyContent={'center'}>
          {pageContentMarkup}
        </Box>
      )}
    </ColumnsV2>
  );
};

Body.displayName = 'Body';

export { Body };
