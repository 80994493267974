import { Box, styled } from '@withjoy/joykit';

export const TextEllipsisWrapper = styled(Box)<{ lineClamp?: number }>`
  display: -webkit-box;
  -webkit-line-clamp: ${props => props.lineClamp || 1};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  white-space: break-spaces;
`;
