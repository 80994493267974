import { createTelemetryObject, createTelemetry } from '@shared/core/analytics';
import { PageFunctionType } from '@shared/core/analytics/telemetry/types';

export type PagesForTelemetry = 'hotelbooking' | 'roomblockhotel' | 'joyPlaceFallback';

const pages: { [tab in PagesForTelemetry]: PageFunctionType } = {
  hotelbooking: (userRole: string) => ({
    category: 'travel',
    pagePrefix: 'guest',
    page: 'hotelbooking',
    extraPageInfo: {
      role: userRole
    }
  }),
  joyPlaceFallback: (userRole: string) => ({
    category: 'travel',
    pagePrefix: 'guest',
    page: 'joyPlaceFallback',
    extraPageInfo: {
      role: userRole
    }
  }),
  roomblockhotel: (userRole: string) => ({
    category: 'travel',
    pagePrefix: 'guest',
    page: 'roomblockhotel',
    extraPageInfo: {
      role: userRole
    }
  })
};

const hotelBookingGuestSiteTelemetry = createTelemetryObject({
  actions: {
    guestPartnerHotelBookingRedirect: (destinationURL: string, destinationDomain: string, success: boolean) => ({
      action: 'GuestPartnerHotelBookingRedirect',
      category: 'travel',
      label: 'hotelbooking',
      extraInfo: {
        name: 'guestPartnerHotelBookingRedirect',
        destinationURL: destinationURL || '',
        destinationDomain: destinationDomain || '',
        success
      }
    }),
    guestHotelBookingRedirect: (destinationURL: string, destinationDomain: string, success: boolean) => ({
      action: 'GuestHotelBookingRedirect',
      category: 'travel',
      label: 'hotelbooking',
      extraInfo: {
        name: 'guestHotelBookingRedirect',
        destinationURL: destinationURL || '',
        destinationDomain: destinationDomain || '',
        success
      }
    }),
    guestHotelBookingQueryError: () => ({
      action: 'GuestHotelBookingRedirect',
      category: 'travel',
      label: 'hotelbooking',
      extraInfo: {
        name: 'guestHotelBookingRedirectQueryError'
      }
    }),
    guestRoomblockHotelRedirect: (destinationURL: string, destinationDomain: string, success: boolean, callback: () => void) => ({
      action: 'GuestRoomblockHotelRedirect',
      category: 'travel',
      label: 'roomblockhotel',
      extraInfo: {
        name: 'guestRoomblockHotelRedirect',
        destinationURL: destinationURL || '',
        destinationDomain: destinationDomain || '',
        success
      },
      callback
    }),
    guestRoomblockHotelQueryError: () => ({
      action: 'GuestRoomblockHotelRedirect',
      category: 'travel',
      label: 'roomblockhotel',
      extraInfo: {
        name: 'guestRoomblockHotelQueryError'
      }
    })
  },
  pages
});

const { TelemetryProvider, useTelemetry: useHotelBookingGuestSiteTelemetry } = createTelemetry(hotelBookingGuestSiteTelemetry);
export { TelemetryProvider, useHotelBookingGuestSiteTelemetry };
