import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { brannanConfig } from '@apps/guest/packages/layout-engine/layouts/LayoutBrannan';
import { usePreviewListener, DesignUpdateEventHandler, LegacyPreviewIframeMessage, MessageAdminEventPageChange } from '@shared/utils/previewMessageBus';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { parseColorPalette, parseDesignPreferences } from '@shared/utils/websiteDesign';
import { useHistory } from '@react-router';
import { EventPageRoutePaths, SupportedEventPages } from './GuestSite.routes';
import { findEventPageByPageSlug, findEventPageByType } from '@apps/guest/packages/layout-engine/layouts/layout.utils';
import type { GuestSiteEvent, OnUnmountCallback, PreviewEventHandler } from '@apps/guest/packages/layout-engine/layouts/layout.types';
import { ColorPointerLocation, DesignLayoutType, EventDesignFragment, EventPageFragment, EventPageType, PhotoXPosition, PhotoYPosition } from '@graphql/generated';
import type { Draft } from 'immer';
import chroma from 'chroma-js';

export type OnPreviewListenerUpdate = (cb: (eventDraft: Draft<GuestSiteEvent>, setCurrentAdminPage: Dispatch<SetStateAction<string>>) => void) => void;

type PreviewListenerProps = Readonly<{
  eventDesign: Maybe<EventDesignFragment>;
  eventPageRoutes: EventPageRoutePaths;
  onUpdate: OnPreviewListenerUpdate;
  onRefetchData: (message: MessageAdminEventPageChange) => void;
}>;

export const PreviewListener = ({ eventDesign, eventPageRoutes, onRefetchData, onUpdate }: PreviewListenerProps) => {
  const history = useHistory();
  const designChangeRef = useRef<EventDesignFragment | undefined>(eventDesign ?? undefined);

  /**
   * Specific logic to handle the case when the "story" page has no content
   *  in order to determine the behavior of the "tidbits" route.
   * @param update - The update message received from the iframe.
   * @param eventDraft - The draft of the event containing the pages.
   *  @returns The appropriate event page type based on the logic.
   */
  const checkStoryTidbitsAlohaLogic = (update: LegacyPreviewIframeMessage, eventDraft: Draft<GuestSiteEvent>) => {
    if (update.value === EventPageType.story) {
      if (eventDesign?.websiteLayout.layoutType === DesignLayoutType.aloha && !eventDraft.pages.find(page => page.type === EventPageType.story)?.subTitle) {
        return EventPageType.tidbits;
      }
      return EventPageType.story;
    }
    return update.value;
  };

  const handleDesignUpdate = useEventCallback<DesignUpdateEventHandler>(message => {
    onUpdate((eventDraft, setCurrentAdminPage) => {
      if (message.action === 'fromWebsiteDesignerPayload') {
        eventDraft.eventDesign = message.value.eventDesign;
      }
      // NOTE: This block ensures backwards compatibility with the angular app.
      else if (message.action === 'fromList') {
        message.value.forEach(update => {
          // When previewing cover photo, navigate to the target page
          if (update.key === 'currentPageType') {
            setCurrentAdminPage(update.value);
            const pathRoute = checkStoryTidbitsAlohaLogic(update, eventDraft);
            const route = eventPageRoutes[pathRoute as SupportedEventPages];
            if (route) {
              history.push({ pathname: route.path });
            } else if (update.value) {
              history.push({ pathname: update.value }); // For custom page preview
            }
          } else {
            _updateFromLegacyPreviewMessage(eventDraft, update);
          }
        });
      }
    });
  });

  // Subscribe to preview updates
  usePreviewListener({ onDesignUpdate: handleDesignUpdate, onAdminEventPageChange: onRefetchData });

  // Trigger preview callbacks after changes are made
  // IE navigate to a certain page when color changes
  useEffect(() => {
    const prevEventdesign = designChangeRef.current;
    designChangeRef.current = eventDesign || undefined;

    if (eventDesign && prevEventdesign) {
      // Update priorities:
      //  - Layout
      //  - Theme
      //  - Primary fill color
      //  - Font

      const { hasLayoutChanged, hasThemeChanged, hasPrimaryFillColorChanged, hasFontChanged, hasGrapicAccentChanged } = _compareDesigns(eventDesign, prevEventdesign);

      let cleanUpHandlers: Array<OnUnmountCallback> | void;

      const handlerArgs: Parameters<PreviewEventHandler>[0] = {
        history,
        eventPageRoutes
      };

      if (hasLayoutChanged) {
        cleanUpHandlers = brannanConfig.previewEventHandlers.onLayoutChange(handlerArgs);
      } else if (hasThemeChanged) {
        cleanUpHandlers = brannanConfig.previewEventHandlers.onThemeChange(handlerArgs);
      } else if (hasPrimaryFillColorChanged) {
        cleanUpHandlers = brannanConfig.previewEventHandlers.onPrimaryFillColorChange(handlerArgs);
      } else if (hasFontChanged) {
        cleanUpHandlers = brannanConfig.previewEventHandlers.onFontChange(handlerArgs);
      } else if (hasGrapicAccentChanged) {
        cleanUpHandlers = brannanConfig.previewEventHandlers.onGraphicAccentChange(handlerArgs);
      }

      return () => {
        (cleanUpHandlers || []).forEach(cb => cb?.());
      };
    }
    return () => {};
  }, [eventDesign, eventPageRoutes, designChangeRef, history]);

  // This component is used for side effects, not rendering.
  return null;
};

///////////////////////////////////////////////////////////////////////////////////

const _compareDesigns = (current: EventDesignFragment, previous: EventDesignFragment) => {
  const currentValues = _collectFieldsToCompare(current);
  const previousValues = _collectFieldsToCompare(previous);
  return {
    hasLayoutChanged: _hasValueChanged(currentValues.websiteLayout, previousValues.websiteLayout),
    hasThemeChanged: _hasValueChanged(currentValues.themeId, previousValues.themeId),
    hasPrimaryFillColorChanged: _hasValueChanged(currentValues.primaryFillColor, previousValues.primaryFillColor),
    hasFontChanged: _hasValueChanged(currentValues.fontId, previousValues.fontId),
    hasGrapicAccentChanged: _hasValueChanged(currentValues.graphicAccent, previousValues.graphicAccent)
  };
};

const _collectFieldsToCompare = (
  eventDesign: EventDesignFragment
): Readonly<{
  fontId: string;
  graphicAccent: Maybe<string>;
  primaryFillColor: string | undefined;
  themeId: string;
  websiteLayout: DesignLayoutType;
}> => {
  const { font, colorPalette, theme, websiteLayout, accent } = eventDesign;
  const { primaryFillColor } = parseColorPalette(colorPalette);

  return { fontId: font.id, graphicAccent: accent, primaryFillColor: primaryFillColor?.color.hex, themeId: theme.themeId, websiteLayout: websiteLayout.layoutType };
};

// Shallow comparison
const _hasValueChanged = (a: unknown, b: unknown) => a !== b;

///////////////////////////////////////////////////////////////////////////////////

const _updateFromLegacyPreviewMessage = (eventDraft: Draft<GuestSiteEvent>, update: LegacyPreviewIframeMessage): void => {
  const { pages: pagesDraft, eventDesign, pageContainers: pageContainersDraft } = eventDraft;
  switch (update.key) {
    case 'coverPhoto': {
      const { page, pageSlug, asset } = update.value;
      const pageDraft =
        page === EventPageType.custom && pageSlug
          ? (findEventPageByPageSlug(pagesDraft, pageSlug, true) as EventPageFragment | undefined)
          : (findEventPageByType(pagesDraft, page) as EventPageFragment | undefined);
      if (pageDraft) {
        if (!asset) {
          pageDraft.photo = null;
        } else {
          pageDraft.photo = {
            id: `preview-photo-${asset.assetId}`,
            height: asset.height,
            layout: {
              alignX: asset.layout?.alignX || 'center',
              alignY: asset.layout?.alignY || 'center'
            },
            transform: null,
            url: asset.url,
            width: asset.width
          };
          const pageContainerDraft = pageContainersDraft?.find(container => container.id === pageDraft?.id);
          if (pageContainerDraft) {
            pageContainerDraft.mainPhotoAlignment = {
              x: (asset.layout?.alignX as PhotoXPosition) || PhotoXPosition.center,
              y: (asset.layout?.alignY as PhotoYPosition) || PhotoYPosition.center
            };
          }
        }
        if (pageDraft?.type === 'welcome') {
          eventDraft.photo = asset
            ? {
                id: `preview-photo-${asset.assetId}`,
                url: asset.url,
                height: asset.height,
                layout: {
                  alignX: asset.layout?.alignX || 'center',
                  alignY: asset.layout?.alignX || 'center'
                },
                width: asset.width
              }
            : null;
        }
      }
      return;
    }

    case 'eventDisplayName': {
      eventDraft.info.eventDisplayName = update.value;
      return;
    }

    case 'eventDate': {
      if (eventDraft.info.finalizedDate?.dateString) {
        eventDraft.info.finalizedDate.dateString = update.value.dateString;
        eventDraft.info.finalizedDate.timeString = update.value.timeString;
      }
      return;
    }

    case 'eventLocation': {
      eventDraft.info.location = update.value;
      return;
    }

    case 'greetings': {
      eventDraft.info.greeting = update.value;
      // brannan uses pages.subtitle for welcome page
      const welcomePage = eventDraft.pages.find(page => page.type === EventPageType.welcome);
      if (welcomePage) {
        welcomePage.subTitle = update.value;
      }
      return;
    }
    default:
      break;
  }

  if (eventDesign) {
    switch (update.key) {
      case 'font': {
        eventDesign.font.fontFamily = update.value;
        break;
      }
      case 'theme': {
        eventDesign.theme.themeId = update.value;
        break;
      }
      case 'color': {
        const { primaryFillColor } = parseColorPalette(eventDesign.colorPalette);
        if (primaryFillColor) {
          const color = update.value;
          const hex = chroma.gl(color.r, color.g, color.b, color.a).hex();
          primaryFillColor.color.hex = hex;
          primaryFillColor.color.isLight = hex === '#ffffff';
        }
        break;
      }
      case 'enableBackgroundTranslucency': {
        const { brannanBackgroundTransparency } = parseDesignPreferences(eventDesign.designPreferences);
        if (brannanBackgroundTransparency) {
          brannanBackgroundTransparency.rangeValue = update.value ? 0 : 1;
        }
        break;
      }

      case 'suggestedColor': {
        const { brannanColorPreference } = parseDesignPreferences(eventDesign.designPreferences);
        if (brannanColorPreference) {
          brannanColorPreference.colorPointerPosition = update.value ? ColorPointerLocation.theme : ColorPointerLocation.event;
        }
        break;
      }

      case 'backgroundType': {
        const backgroundType = update.value;
        const { brannanInnerFrameBorder } = parseDesignPreferences(eventDesign.designPreferences);

        if (brannanInnerFrameBorder) {
          brannanInnerFrameBorder.booleanValue = backgroundType !== 'classic';
        }
        break;
      }
    }
  }
};
