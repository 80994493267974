import { Flex } from '@withjoy/joykit';
import React, { useEffect, useState } from 'react';
import { useMount } from '@shared/utils/hooks/useMount';
import PageNotFound from '@apps/pageNotFound/PageNotFound';
import { useHotelBookingGuestSiteTelemetry } from '../../HotelBooking.telemetry';
import { getValidBookingLink } from '../../HotelBooking.utils';
import { useFeatureValue } from '@shared/core/featureFlags';
import { generateGoogleUrl } from '@shared/utils/googleUrl';
import { JoyLogoLoader } from '@shared/components/JoyLogoLoader';
import { useEventInfo } from '@shared/utils/eventInfo';
import { useCalculatedCheckIn, useCalculatedCheckOut } from '@shared/hooks/useDateUtils';
import { JoyPlace, useJoyPlace } from '@shared/hooks/joyPlace/useJoyPlace';

/**
 * Formats a Date object into a string in the format 'YYYY-MM-DD'.
 */
const formatDate = (dateObj: Date | null): string | null =>
  dateObj && !isNaN(dateObj.getTime()) ? `${dateObj.getFullYear()}-${String(dateObj.getMonth() + 1).padStart(2, '0')}-${String(dateObj.getDate()).padStart(2, '0')}` : null;

export const JoyPlacePartnerRedirection: React.FC<{ joyPlaceId: string }> = ({ joyPlaceId }) => {
  const [showNotFound, setShowNotFound] = useState<boolean>(false);
  const { eventInfo } = useEventInfo();
  const { getJoyPlaceById } = useJoyPlace();
  const telemetry = useHotelBookingGuestSiteTelemetry();
  const checkIn = useCalculatedCheckIn(eventInfo?.finalizedDate?.dateString || undefined);
  const checkOut = useCalculatedCheckOut(eventInfo?.finalizedDate?.dateString || undefined);
  const { loading, value } = useFeatureValue('zentrumHubEnabled');

  useMount(() => {
    telemetry.joyPlaceFallback.enter();
  });

  /**
   * Try to open the Zentrum Hub partner URL for the given JoyPlace.
   * If the JoyPlace does not have a Zentrum Hub ID, or if the Zentrum Hub feature is disabled,
   * fall back to the JoyPlace URL, and if that is not available, fall back to the Google Hotel URL.
   * @param joyPlace
   */
  const openZHPartnerUrl = (joyPlace: JoyPlace) => {
    const zhUrl = `https://hotels.withjoy.com/hotels/v2/${joyPlace.zhId}?campaignid=${eventInfo?.eventId || ''}${checkIn ? `&checkin=${formatDate(checkIn)}` : ''}${
      checkOut ? `&checkout=${formatDate(checkOut)}` : ''
    }`;
    const validBookingLink = getValidBookingLink(zhUrl);
    if (validBookingLink) {
      telemetry.guestPartnerHotelBookingRedirect(zhUrl, validBookingLink.hostname, true);
      window.location.replace(zhUrl);
    } else {
      openJoyPlaceUrl(joyPlace);
    }
  };

  /**
   * Try to open the JoyPlace URL for the given JoyPlace.
   * If the JoyPlace URL is not available, fall back to the Google Hotel URL.
   * @param joyPlace
   */
  const openJoyPlaceUrl = (joyPlace: JoyPlace) => {
    const validBookingLink = getValidBookingLink(joyPlace.url);
    if (validBookingLink) {
      telemetry.guestPartnerHotelBookingRedirect(joyPlace.url, validBookingLink.hostname, true);
      const url = `https://redirect.withjoy.com/redirect?url=${window.encodeURIComponent(joyPlace.url)}`;
      window.location.replace(url);
    } else {
      openGoogleHotelUrl(joyPlace);
    }
  };

  /**
   *  Open the Google Hotel URL for the given JoyPlace.
   * @param joyPlace
   */
  const openGoogleHotelUrl = (joyPlace: JoyPlace) => {
    const googleUrl = generateGoogleUrl({
      placeId: joyPlace.googlePlaceId as string,
      name: joyPlace.name as string,
      address: joyPlace.address as string,
      lat: `${joyPlace.lat}` as string,
      lng: `${joyPlace.long}` as string
    });
    const validHotelLink = getValidBookingLink(googleUrl);
    telemetry.guestPartnerHotelBookingRedirect(googleUrl, validHotelLink?.hostname || '', true);
    window.location.replace(googleUrl);
  };

  useEffect(() => {
    if (!joyPlaceId || loading) return;

    getJoyPlaceById(joyPlaceId).then(joyPlace => {
      if (!joyPlace) {
        setShowNotFound(true);
        return;
      }

      if (joyPlace.zhId && value === 'on') {
        openZHPartnerUrl(joyPlace);
      } else if (joyPlace.url) {
        openJoyPlaceUrl(joyPlace);
      } else {
        openGoogleHotelUrl(joyPlace);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getJoyPlaceById, joyPlaceId, value, loading]);

  if (showNotFound) {
    return <PageNotFound />;
  }

  return (
    <Flex justifyContent="center" alignItems="center" height="100vh" width="100vw">
      <JoyLogoLoader loaderKey="guest-hotel-booking-partner" />
    </Flex>
  );
};
