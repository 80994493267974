import React from 'react';
import { NavBar, NavBarProps } from '../NavBar';
import { EventInfoFragmentFragment, EventPageFragment, EventPageType } from '@graphql/generated';
import { StyledRoot, StyledTitle, styles } from './Header.styles';
import { Box, ButtonV2, TextV2 } from '@withjoy/joykit';
import { Link } from '@react-router';
import { useEventUserRole } from '@shared/components/AuthProvider';
import { useTranslation } from '@shared/core/i18n';
import { isInIframe } from '@shared/utils/isInIframe';
import { NavbarEventPropsInterface } from '@apps/guest/packages/layout-engine/layouts/layout.types';
import { animationTransition } from '@shared/utils/animationTransition';
import { useMediaQuery } from '@withjoy/joykit/utils';
import { InlineEditor } from '@shared/components';
import { sendMessageToParentWindow } from '@shared/utils/previewMessageBus';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';

export interface HeaderProps
  extends Readonly<{
    eventHandle: string;
    info: EventInfoFragmentFragment;
    pages: ReadonlyArray<EventPageFragment>;
    navBarVariant: NavBarProps['variant'];
    applicatorProp: object;
    handleMenuClicked?: (item: string) => void;
    handleEditEventClicked?: () => void;
    eventProps: NavbarEventPropsInterface;
    isCustomPage: boolean;
    hasBanner?: boolean;
    page: Maybe<EventPageType>;
    pageSlug?: string;
  }> {}

const Header: React.FC<HeaderProps> = ({
  applicatorProp,
  eventHandle,
  info,
  pages,
  navBarVariant,
  handleMenuClicked,
  handleEditEventClicked,
  eventProps,
  isCustomPage,
  hasBanner,
  page,
  pageSlug,
  ...restProps
}) => {
  const { isAdmin } = useEventUserRole();
  const isMobile = useMediaQuery(theme => theme.mediaQueries.between(0, { breakpointAlias: 'sm2' }));

  const { t2 } = useTranslation('eventMenu');
  const editEventText = t2('editEvent');
  const inlineEditorTrans = useTranslation('joykit').t2('inlineEditor');

  const isPreviewing = isInIframe();

  const handleEditTextClick = useEventCallback(() => {
    // send the message to the parent window to open the text editor
    sendMessageToParentWindow({
      action: 'inlineEditingInteraction',
      source: 'joyPreview',
      value: { action: 'editText', inlineEditData: { textContent: info.eventDisplayName || '', label: inlineEditorTrans.displayName, validationConstraints: { maxLength: 30 } } }
    });
  });

  return (
    <StyledRoot
      {...restProps}
      overflow={isCustomPage ? 'hidden' : 'visible'}
      position={'relative'}
      minHeight={isCustomPage ? null : [291, 200, null, 'initial']}
      {...applicatorProp}
    >
      <Box height={{ _: isCustomPage ? 76 : 'auto', sm2: 'auto' }} transition={animationTransition('height')} width="100%">
        {/* Hide title from Brannan Mobile */}
        {(!isCustomPage || !isMobile) && (
          <StyledTitle __css={styles.title} data-testid="header-title">
            <InlineEditor
              elementLabel={inlineEditorTrans.displayName}
              actionData={{
                edit: handleEditTextClick
              }}
              wrapperCSS={{
                zIndex: 1200
              }}
              inset={isMobile ? -12 : -16}
              componentName="eventDisplayName"
              pageName={page}
              pageSlug={pageSlug || ''}
            >
              <TextV2 typographyVariant={['display4', 'display6']}>{info.eventDisplayName}</TextV2>
            </InlineEditor>
          </StyledTitle>
        )}

        {isAdmin && !isPreviewing && navBarVariant === 'inline' && (
          <ButtonV2
            onClick={handleEditEventClicked}
            intent="primary"
            size="sm"
            typographyVariant="button1"
            variant="outline"
            position="absolute"
            top="19px"
            right="16px"
            as={Link}
            to={`/${eventHandle}/edit/dashboard`}
          >
            {editEventText}
          </ButtonV2>
        )}
        <NavBar
          handleMenuClicked={handleMenuClicked}
          title={info.eventDisplayName}
          variant={navBarVariant}
          data-testid={'header-nav'}
          eventHandle={eventHandle}
          pages={pages}
          eventProps={eventProps}
          isCustomPage={isCustomPage}
          hasBanner={hasBanner}
        />
      </Box>
    </StyledRoot>
  );
};

Header.displayName = 'Header';

export { Header };
