import React from 'react';
import { useHistory, useRouteMatch } from '@shared/core';
import { createContext } from '@shared/utils/createContext';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { StationeryTemplateCategoryEnum } from '@graphql/generated';

export const CARDS_BASE_PATH = '/cards';

const createPaths = (
  path: string | undefined,
  options: {
    public: boolean;
    admin: boolean;
  }
) => {
  const paths = [];

  if (options.public) {
    paths.push(`${CARDS_BASE_PATH}${path}`);
  }

  if (options.admin) {
    paths.push(`/:eventHandle/edit${CARDS_BASE_PATH}${path}`);
  }

  return { paths };
};

const CARD_ROUTES = {
  dashboard: createPaths('', { public: true, admin: true }),
  designsGallery: createPaths('', { public: true, admin: true }),
  drafts: createPaths('/drafts', { public: false, admin: true }),
  paperCardCustomizer: createPaths('/customize/:cardDraftId', { public: false, admin: true }),
  digitalCardCustomizer: createPaths('/digital/customize/:cardDraftId', { public: false, admin: true }),

  editOrder: createPaths('/orders/:printOrderNumber/customize', { public: false, admin: true }),
  orderHistory: createPaths('/orders', { public: false, admin: true }),
  orderDetails: createPaths('/orders/:printOrderNumber', { public: false, admin: true }),

  designConcierge: createPaths('/concierge', { public: false, admin: true })
} as const;

type CardsRouterContext = {
  getCustomizeDraftPath: (cardDraftId: string) => string;
  getConciergePath: (setName: string) => string;
  getOrderDetailsPath: (printOrderNumber: string) => string;
  getCustomizeOrderDetailsPath: (printOrderNumber: string) => string;
  getOrdersPath: () => string;
  getDraftsPath: () => string;
  getCardDashboardPath: (cardCategory?: StationeryTemplateCategoryEnum) => string;
  getDigitalCustomizeDraftPath: (cardDraftId: string) => string;
  goToCustomizeDraft: (draftId: string) => void;
  goToConcierge: (setName: string) => void;
  goToEventDashboard: () => void;
  goToCardDashboard: (cardCategory?: StationeryTemplateCategoryEnum) => void;
  goToCardDashboardByCategory: (cardCategory: StationeryTemplateCategoryEnum) => void;
  goToOrderDetails: (orderNumber: string) => void;
  goToCustomizeOrderDetails: (orderNumber: string) => void;
  goToCardDrafts: () => void;
  goToOrders: () => void;
  goToDigitalCustomizeDraft: (theme: string) => void;
  routes: typeof CARD_ROUTES;

  isPublicShopRoute: boolean;
};

const [Provider, useCardsRouterContext] = createContext<CardsRouterContext>({ name: 'CardsRouteProvider' });

const CardsRoutesProvider = ({ children, eventHandle }: { eventHandle?: string; children: React.ReactNode }) => {
  const history = useHistory();

  const isPublicShopRoute = !!useRouteMatch('/cards');

  const buildRoute = useEventCallback((path: string = '') => {
    return eventHandle ? `/${eventHandle}/edit${CARDS_BASE_PATH}${path}` : `${CARDS_BASE_PATH}${path}`;
  });

  const getDigitalCustomizeDraftPath = useEventCallback<CardsRouterContext['getDigitalCustomizeDraftPath']>(draftId => {
    return buildRoute(`/digital/customize/${draftId}`);
  });

  const getOrderDetailsPath = useEventCallback<CardsRouterContext['getOrderDetailsPath']>(printOrderNumber => {
    return buildRoute(`/orders/${printOrderNumber}`);
  });

  const getCustomizeOrderDetailsPath = useEventCallback<CardsRouterContext['getCustomizeOrderDetailsPath']>(printOrderNumber => {
    return buildRoute(`/orders/${printOrderNumber}/customize`);
  });

  const getCustomizeDraftPath = useEventCallback<CardsRouterContext['getCustomizeDraftPath']>(cardDraftId => {
    return buildRoute(`/customize/${cardDraftId}`);
  });

  const getConciergePath = useEventCallback<CardsRouterContext['getConciergePath']>(setName => {
    return buildRoute(`/concierge?setName=${setName}`);
  });

  const getCardDashboardPath = useEventCallback<CardsRouterContext['getCardDashboardPath']>(cardCategory => {
    return buildRoute(cardCategory ? `?ctgy=${cardCategory}` : undefined);
  });

  const getOrdersPath = useEventCallback(() => {
    return buildRoute('/orders');
  });
  const getDraftsPath = useEventCallback(() => {
    return buildRoute('/drafts');
  });

  const goToDigitalCustomizeDraft = useEventCallback((draftId: string) => {
    history.push(getDigitalCustomizeDraftPath(draftId));
  });

  const goToOrders = useEventCallback(() => {
    history.push(getOrdersPath());
  });

  const goToCustomizeDraft = useEventCallback((stationeryDraftId: string) => {
    history.push(getCustomizeDraftPath(stationeryDraftId));
  });

  const goToConcierge = useEventCallback((setName: string) => {
    history.push(getConciergePath(setName));
  });

  const goToEventDashboard = useEventCallback(() => {
    history.push(eventHandle ? `/${eventHandle}/edit` : '/event/edit');
  });

  const goToCardDashboard = useEventCallback((cardCategory?: StationeryTemplateCategoryEnum) => {
    history.push(getCardDashboardPath(cardCategory));
  });

  const goToCardDashboardByCategory = useEventCallback((cardCategory: StationeryTemplateCategoryEnum) => {
    history.push(getCardDashboardPath(cardCategory));
  });

  const goToOrderDetails = useEventCallback((printOrderNumber: string) => {
    history.push(getOrderDetailsPath(printOrderNumber));
  });

  const goToCustomizeOrderDetails = useEventCallback((printOrderNumber: string) => {
    history.push(getCustomizeOrderDetailsPath(printOrderNumber));
  });

  const goToCardDrafts = useEventCallback(() => {
    history.push(getDraftsPath());
  });

  return (
    <Provider
      value={{
        goToOrders,
        getCardDashboardPath,
        getDraftsPath,
        goToCardDrafts,
        getOrdersPath,
        goToCardDashboard,
        goToCardDashboardByCategory,
        goToEventDashboard,
        goToCustomizeDraft,
        getCustomizeDraftPath,
        goToConcierge,
        getConciergePath,
        getOrderDetailsPath,
        getCustomizeOrderDetailsPath,
        goToOrderDetails,
        goToCustomizeOrderDetails,
        getDigitalCustomizeDraftPath,
        goToDigitalCustomizeDraft,

        routes: CARD_ROUTES,
        isPublicShopRoute
      }}
    >
      {children}
    </Provider>
  );
};

export { CardsRoutesProvider, useCardsRouterContext };
