import { Box } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ActionBar, ActionData } from './components/ActionBar/ActionBar';
import { withWindow } from '@shared/utils/withWindow';
import { InlineEditorWrapper } from './InlineEditor.styles';
import { isInIframe } from '@shared/utils/isInIframe';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useQueryParamHelper } from '@shared/core/queryString';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { sendMessageToParentWindow } from '@shared/utils/previewMessageBus';
import { ParentMessageListener } from './components/ParentMessageListener/ParentMessageListener';
import { telemetryData } from './InlineEditor.telemetry';
import { AnimatedBorder } from './components/AnimatedBorder/AnimatedBorder';
import { CSSObject } from '@withjoy/joykit/components/Box/Box.types';
import { EventPageType } from '@graphql/generated';

type InlineEditorProps = {
  elementLabel: string;
  actionData: ActionData;
  wrapperType?: 'actionInside' | 'actionOutside';
  minHeight?: string;
  wrapperCSS?: CSSObject;
  containerCSS?: CSSObject;
  inset?: number;
  componentName: 'pagePhoto' | 'eventDisplayName' | 'eventDate' | 'greetings' | 'eventLocation';
  pageName: Maybe<EventPageType>;
  pageSlug: string;
};

export const InlineEditor: FC<InlineEditorProps> = props => {
  const {
    componentName,
    pageName,
    pageSlug,
    elementLabel,
    actionData,
    wrapperType = 'actionOutside',
    minHeight,
    children,
    wrapperCSS = {},
    containerCSS = {},
    inset = -16
  } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [scaleValue, setScaleValue] = useState(1);
  const [isHovered, setIsHovered] = useState(false);
  const [position, setPosition] = useState<'top' | 'bottom'>('top');

  const queryParamHelper = useQueryParamHelper();
  const [isMobile] = useResponsive({ values: { mobile: true, tablet: false } }, false);

  const inlineEditingEnabled = useMemo(() => queryParamHelper.getValue('feature.enableInlineEditing') === 'true', [queryParamHelper]);
  const isParentInMobileResolution = useMemo(() => queryParamHelper.getValue('feature.isMobileInlineEditMode') === 'true', [queryParamHelper]);

  const adminDashboardInlineEditingEnabled = useFeatureValue('adminDashboardInlineEditingExperiment', { skip: !inlineEditingEnabled }).value === 'treatment';
  const adminDashboardInlineEditingHoverTelemetryEnabled = useFeatureValue('adminDashboardInlineEditingHoverTelemetry').value === 'on';

  const wrapperRef = useRef<HTMLDivElement>(null);
  const childWrapperRef = useRef<HTMLDivElement>(null);
  const actionBarRef = useRef<HTMLDivElement>(null);

  const isPreviewing = isInIframe();

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const el = e.target;
      const domNode = wrapperRef.current;

      if (!(domNode && el instanceof Node && domNode.contains(el)) && isEditing) {
        if (isEditing) {
          sendMessageToParentWindow({
            action: 'telemetryData',
            value: telemetryData.inlineEditCTAClick(componentName, pageName, pageSlug, 'focusOut'),
            source: 'joyPreview'
          });
        }
        setIsEditing(false);
        // to show the preview button when the inline editor is closed
        sendMessageToParentWindow({ action: 'togglePreviewButton', value: { show: true }, source: 'joyPreview' });
      }
    };
    if (isEditing) {
      withWindow(window => {
        window.document.addEventListener('mousedown', handleClickOutside);
      });
    }
    return () => {
      withWindow(window => {
        window.document.removeEventListener('mousedown', handleClickOutside);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  const handleOnClick = useEventCallback(() => {
    // toggles the edit mode
    setIsEditing(prev => {
      // to show/hide the preview button when the inline editor is opened/closed
      sendMessageToParentWindow({ action: 'togglePreviewButton', value: { show: prev }, source: 'joyPreview' });
      sendMessageToParentWindow({
        action: 'telemetryData',
        value: telemetryData.inlineEditCTAClick(componentName, pageName, pageSlug, prev ? 'focusOut' : 'focusIn'),
        source: 'joyPreview'
      });
      return !prev;
    });
  });

  const applyScaling = useCallback((value: number) => value / scaleValue, [scaleValue]);

  const onActionClick = useEventCallback((action: string) => {
    sendMessageToParentWindow({ action: 'telemetryData', value: telemetryData.inlineActionClicked(componentName, pageName, pageSlug), source: 'joyPreview' });
  });

  const handleOnMouseEnter = useCallback(() => {
    if (!(isMobile && isParentInMobileResolution)) {
      if (adminDashboardInlineEditingHoverTelemetryEnabled) {
        sendMessageToParentWindow({ action: 'telemetryData', value: telemetryData.inlineEditHovered(componentName, pageName, pageSlug), source: 'joyPreview' });
      }
      setIsHovered(true);
    }
  }, [adminDashboardInlineEditingHoverTelemetryEnabled, componentName, isMobile, isParentInMobileResolution, pageName, pageSlug]);

  useEffect(() => {
    const handlePosition = () => {
      const rect = actionBarRef.current?.getBoundingClientRect();
      // Check if the element's top is out of the viewport
      if (rect?.top && rect.top < 0) {
        setPosition('bottom');
      }
    };

    // Run on mount and on window resize or scroll
    setTimeout(() => handlePosition(), 300);
  }, []);

  return (
    <>
      {adminDashboardInlineEditingEnabled ? (
        <InlineEditorWrapper
          isPreviewing={isPreviewing}
          position="relative"
          ref={wrapperRef}
          _after={isEditing && wrapperType === 'actionInside' ? { content: '""', inset: 0, position: 'absolute', background: 'rgba(78, 6, 242, 0.20)' } : {}}
          _hover={
            !isEditing && !(isMobile && isParentInMobileResolution) && wrapperType === 'actionInside'
              ? { _after: { content: '""', position: 'absolute', inset: 0, background: 'rgba(78, 6, 242, 0.10)' } }
              : {}
          }
          _active={
            !isEditing && !(isMobile && isParentInMobileResolution) && wrapperType === 'actionInside'
              ? { _after: { content: '""', position: 'absolute', inset: 0, background: 'rgba(78, 6, 242, 0.20)' } }
              : {}
          }
          __css={wrapperCSS}
        >
          <ParentMessageListener
            onScaleChange={value => setScaleValue(value)}
            onParentFocus={() => {
              if (isEditing) {
                sendMessageToParentWindow({
                  action: 'telemetryData',
                  value: telemetryData.inlineEditCTAClick(componentName, pageName, pageSlug, 'focusOut'),
                  source: 'joyPreview'
                });
              }
              setIsEditing(false);
            }}
          />
          <Box
            ref={childWrapperRef}
            minHeight={minHeight || childWrapperRef.current?.firstElementChild?.clientHeight}
            __css={containerCSS}
            _hover={
              !isEditing && !(isMobile && isParentInMobileResolution)
                ? {
                    _after: {
                      position: 'absolute',
                      inset: wrapperType === 'actionOutside' ? pxToRem(applyScaling(inset)) : 0,
                      background: wrapperType === 'actionOutside' ? 'rgba(78, 6, 242, 0.10)' : ''
                    },
                    _before: {
                      content: `"${elementLabel}"`,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      color: 'white',
                      top: wrapperType === 'actionOutside' ? pxToRem(applyScaling(-45)) : pxToRem(applyScaling(16)),
                      left: wrapperType === 'actionOutside' ? pxToRem(applyScaling(inset)) : pxToRem(applyScaling(16)),
                      backgroundColor: '#4E06F2',
                      fontFamily: 'Inter UI',
                      fontSize: pxToRem(applyScaling(13)),
                      fontStyle: 'normal',
                      fontWeight: 600,
                      lineHeight: pxToRem(18.2),
                      letterSpacing: pxToRem(-0.032),
                      borderRadius: pxToRem(applyScaling(8)),
                      paddingX: pxToRem(applyScaling(8)),
                      height: pxToRem(applyScaling(24)),
                      zIndex: 'banner'
                    }
                  }
                : {}
            }
            _active={
              !isEditing && !(isMobile && isParentInMobileResolution)
                ? {
                    _after: {
                      position: 'absolute',
                      inset: wrapperType === 'actionOutside' ? pxToRem(applyScaling(inset)) : 0,
                      background: wrapperType === 'actionOutside' ? 'rgba(78, 6, 242, 0.20)' : ''
                    },
                    _before: {
                      content: `"${elementLabel}"`,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      color: 'white',
                      top: wrapperType === 'actionOutside' ? pxToRem(applyScaling(-45)) : pxToRem(applyScaling(16)),
                      left: wrapperType === 'actionOutside' ? pxToRem(applyScaling(inset)) : pxToRem(applyScaling(16)),
                      backgroundColor: '#4E06F2',
                      fontFamily: 'Inter UI',
                      fontSize: pxToRem(applyScaling(13)),
                      fontStyle: 'normal',
                      fontWeight: 600,
                      lineHeight: pxToRem(18.2),
                      letterSpacing: pxToRem(-0.032),
                      borderRadius: pxToRem(applyScaling(8)),
                      paddingX: pxToRem(applyScaling(8)),
                      height: pxToRem(applyScaling(24)),
                      zIndex: 'banner'
                    }
                  }
                : {}
            }
            _after={{ content: '""' }}
            onClick={handleOnClick}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={() => setIsHovered(false)}
            cursor="pointer"
          >
            {children}
            <AnimatedBorder
              isVisibile={isEditing || isHovered}
              borderWidth={pxToRem(applyScaling(2))}
              borderRadius={`${applyScaling(8)}px`}
              inset={wrapperType === 'actionOutside' ? pxToRem(applyScaling(inset)) : '0px'}
              padding={wrapperType === 'actionOutside' ? 0 : pxToRem(applyScaling(8))}
            />
          </Box>
          <Box
            key={`${elementLabel}-action-bar`}
            position={isMobile && isParentInMobileResolution ? 'fixed' : 'absolute'}
            width="100%"
            zIndex={1300}
            top={(isMobile && isParentInMobileResolution) || position === 'bottom' ? 'unset' : wrapperType === 'actionOutside' ? pxToRem(applyScaling(-80)) : '50%'}
            left="50%"
            transform={wrapperType === 'actionOutside' || (isMobile && isParentInMobileResolution) ? 'translate(-50%)' : 'translate(-50%, -50%)'}
            bottom={isMobile && isParentInMobileResolution ? (isEditing ? pxToRem(applyScaling(24)) : '-100%') : position === 'bottom' ? pxToRem(applyScaling(-80)) : 'unset'}
            opacity={isEditing ? 1 : 0}
            visibility={isEditing ? 'visible' : 'hidden'}
            transition=" bottom 0.5s ease-in-out, opacity 0.5s ease-in-out, visibility 0.5s ease-in-out"
            ref={actionBarRef}
          >
            <ActionBar actionData={actionData} actionView={wrapperType === 'actionOutside' ? 'compact' : 'detailed'} scaleValue={scaleValue} onActionClick={onActionClick} />
          </Box>
        </InlineEditorWrapper>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

InlineEditor.displayName = 'InlineEditor';
