import { TextEllipsisWrapper } from '@shared/utils/formatting/textEllipsis';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { Flex, Box, TextV2 } from '@withjoy/joykit';
import React, { useState } from 'react';
import { useTranslation } from '@shared/core';
import { useIsTextOverflowing } from '../../hooks/useTextLineClampOverflow';

export const AccommodationTileDescription: React.FC<{ description?: string | null; eventPhoto: string; isFavorite: boolean }> = ({ description, eventPhoto, isFavorite }) => {
  const [isCoupleNoteOpen, setIsCoupleNoteOpen] = useState(false);
  const { textRef, isOverflowing } = useIsTextOverflowing(4);
  const handleCoupleNoteOpen = useEventCallback(() => setIsCoupleNoteOpen(isOpen => !isOpen));
  const { t } = useTranslation('guestSiteCustom');
  const accTrans = t('accommodations');

  if (!description) {
    if (isFavorite) {
      return (
        <Flex flexDirection={'row'} columnGap={5}>
          <Box
            minWidth={48}
            width={48}
            height={48}
            borderRadius={48}
            border="2px solid"
            borderColor="mono3"
            objectFit={'contain'}
            backgroundSize={'cover'}
            backgroundPosition={'center'}
            backgroundRepeat={'no-repeat'}
            backgroundImage={`url(${eventPhoto})`}
          ></Box>
          <Flex flexDirection={'column'} justifyContent="center">
            <TextV2 typographyVariant={'button1'} fontFamily={'Inter UI'} fontSize={15} fontWeight={700}>
              {accTrans.favoriteHotel()}
            </TextV2>
          </Flex>
        </Flex>
      );
    } else {
      return null;
    }
  }

  return (
    <Flex flexDirection={'row'} columnGap={5}>
      <Box
        minWidth={48}
        width={48}
        height={48}
        borderRadius={48}
        border="2px solid"
        borderColor="mono3"
        objectFit={'contain'}
        backgroundSize={'cover'}
        backgroundPosition={'center'}
        backgroundRepeat={'no-repeat'}
        backgroundImage={`url(${eventPhoto})`}
      ></Box>
      <Flex flexDirection={'column'}>
        {isFavorite && (
          <TextV2 typographyVariant={'button1'} fontFamily={'Inter UI'} fontSize={15} fontWeight={700}>
            {accTrans.favoriteHotel()}
          </TextV2>
        )}
        <Flex flexDirection={'column'}>
          <TextEllipsisWrapper lineClamp={isCoupleNoteOpen ? 12 : 4} ref={textRef}>
            <TextV2 fontFamily={'Inter UI'} fontSize={15} fontStyle={'italic'} fontWeight={400} lineHeight={'150%'} letterSpacing={'-0.15px'} color={'mono12'}>
              {'"'}
              {description}
              {'"'}
            </TextV2>
          </TextEllipsisWrapper>
          {isOverflowing && (
            <span>
              <TextV2 fontFamily={'Inter UI'} fontSize={15} fontWeight={700} _hover={{ cursor: 'pointer' }} color="mono12" onClick={handleCoupleNoteOpen}>
                {isCoupleNoteOpen ? `${accTrans.less()}` : `${accTrans.more()}`}
              </TextV2>
            </span>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
