import React, { createContext, useContext, useState, ReactNode, FC, useMemo } from 'react';
import { useRouteMatch } from '@react-router';
import { useQueryParams } from '@shared/utils/hooks/useQueryParams';
import { useNativeState } from '@shared/core/native';
import { useGuestAccommodationsInfo } from './hooks/useGuestAccommodationsInfo';

interface GuestSiteStateContextProps {
  showNoLeadDialog: boolean;
  setShowNoLeadDialog: (value: boolean) => void;
  enabledAdminGuestSiteBannerDialog: boolean;
  isNativeAccommodations: boolean;
  hideAccomPreviewUntilFlagEnabled: boolean;
  isAccommodations: boolean;
  hotelTileGuestCountEnabled: boolean;
  hasBookingLink: boolean;
}

const GuestSiteStateContext = createContext<GuestSiteStateContextProps | undefined>(undefined);

interface GuestSiteStateProviderProps {
  eventHandle: string;
  children: ReactNode;
}

export const GuestSiteStateProvider: FC<GuestSiteStateProviderProps> = ({ eventHandle, children }) => {
  const { enabledAdminGuestSiteBannerDialog, hideAccomPreviewUntilFlagEnabled, hotelTileGuestCountEnabled, hasBookingLink } = useGuestAccommodationsInfo(eventHandle);
  const [showNoLeadDialog, setShowNoLeadDialog] = useState(false);
  const queryParams = useQueryParams(); // Check is preview screen - Don't show banner and dialog on preview screen
  const routeMatch = useRouteMatch<{ pageSlug: string }>(`/${eventHandle}/:pageSlug`); // Check for current page - Is accommodations page or not.
  const currentPageSlug = routeMatch?.params.pageSlug;
  const isAccommodations = currentPageSlug === 'accommodations';
  const { isNative } = useNativeState();

  const value = useMemo<GuestSiteStateContextProps>(() => {
    return {
      showNoLeadDialog,
      setShowNoLeadDialog,
      enabledAdminGuestSiteBannerDialog: isAccommodations && queryParams?.preview !== 'true' && enabledAdminGuestSiteBannerDialog,
      isNativeAccommodations: isNative && isAccommodations,
      isAccommodations,
      hideAccomPreviewUntilFlagEnabled: hideAccomPreviewUntilFlagEnabled,
      hotelTileGuestCountEnabled: hotelTileGuestCountEnabled,
      hasBookingLink
    };
  }, [
    showNoLeadDialog,
    isAccommodations,
    queryParams.preview,
    enabledAdminGuestSiteBannerDialog,
    isNative,
    hideAccomPreviewUntilFlagEnabled,
    hotelTileGuestCountEnabled,
    hasBookingLink
  ]);

  return <GuestSiteStateContext.Provider value={value}>{children}</GuestSiteStateContext.Provider>;
};

export const useGuestSiteState = (): GuestSiteStateContextProps => {
  const context = useContext(GuestSiteStateContext);
  if (context === undefined) {
    throw new Error('useGuestSiteState must be used within a GuestSiteStateProvider');
  }
  return context;
};
